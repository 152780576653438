// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  accordion: dynamic(() => import("./Accordion")),
  brand_banner: dynamic(() => import("./BrandBanner")),
  card_grid: dynamic(() => import("./CardGrid")),
  contact: dynamic(() => import("./Contact")),
  course_grid: dynamic(() => import("./CourseGrid")),
  embed: dynamic(() => import("./Embed")),
  featured_service: dynamic(() => import("./FeaturedService")),
  footer_item: dynamic(() => import("./FooterItem")),
  hero: dynamic(() => import("./Hero")),
  image_grid: dynamic(() => import("./ImageGrid")),
  industry_courses: dynamic(() => import("./IndustryCourses")),
  looker_studio: dynamic(() => import("./LookerStudio")),
  modules: dynamic(() => import("./Modules")),
  navigation_item: dynamic(() => import("./NavigationItem")),
  partners: dynamic(() => import("./Partners")),
  post_grid: dynamic(() => import("./PostGrid")),
  product_details: dynamic(() => import("./ProductDetails")),
  product_info: dynamic(() => import("./ProductInfo")),
  product_list: dynamic(() => import("./ProductList")),
  service_two_column: dynamic(() => import("./ServiceTwoColumn")),
  text: dynamic(() => import("./Text")),
  trainers_grid: dynamic(() => import("./TrainersGrid")),
  two_columns: dynamic(() => import("./TwoColumns")),
  video: dynamic(() => import("./Video")),
};
